import { MutableRefObject } from 'react';
import gsap from 'gsap';
import { useAppSelector } from 'redux/hooks';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { AVAILABLE_LANGUAGES } from 'constants/constants';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateRolling = (
  isLanguageSwitcherRolled: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const {
    utils: { toArray },
  } = gsap;
  const LANGUAGE_SELECTORS_OPACITY = isLanguageSwitcherRolled ? 1 : 0;
  const { timeline: rollingTimeline } = useGSAPTimeline(false, undefined, scopeElement, [
    isLanguageSwitcherRolled,
  ]);
  const languageSelectors = toArray<HTMLDivElement>('.language-selector');
  const ROLLING_LANGUAGES_DURATION = 0.2;
  const ROLLING_LANGUAGES_STAGING = 0.2;
  rollingTimeline &&
    languageSelectors.length &&
    rollingTimeline.to(languageSelectors, {
      opacity: LANGUAGE_SELECTORS_OPACITY,
      duration: ROLLING_LANGUAGES_DURATION,
      stagger: {
        each: ROLLING_LANGUAGES_STAGING,
        from: isLanguageSwitcherRolled ? 'start' : 'end',
      },
    });
};

export const useSliding = (scopeElement: MutableRefObject<HTMLDivElement>) => {
  const {
    utils: { toArray },
  } = gsap;
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const transitionXLevel = AVAILABLE_LANGUAGES.findIndex(
    (language) => language === currentLanguage,
  );
  const {
    timeline: slidingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [currentLanguage]);
  const languageSelectors = toArray<HTMLDivElement>('.language-item');
  const SLIDING_LANGUAGES_DURATION = 0.8;
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : SLIDING_LANGUAGES_DURATION;
  slidingTimeline &&
    slidingTimeline.to(languageSelectors, {
      translateX: `-${transitionXLevel * 100}%`,
      duration,
      onComplete: () => setIsFirstLoad(false),
    });
};
