import { FC, memo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { MainLogoLive } from 'components/MainLogoLive/MainLogoLive';
import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import styles from './HomePage.module.scss';

export const HomePage: FC = memo(() => {
  const homePageRef = useRef(null);
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isComponentActive = 'home' === currentlyViewedPage;
  useAnimateAppearing(isComponentActive, homePageRef);
  return (
    <div className={styles.HomePage} ref={homePageRef}>
      <MainLogoLive />
    </div>
  );
});
