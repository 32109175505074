import { FC, memo, useRef, Children, cloneElement, ReactElement } from 'react';

import { ReactChildrenType } from 'types/types';
import { useAnimateNavLinksGroupSliding } from './hooks/useAnimate';
import { HomeButton } from './components/HomeButton/HomeButton';
import { NavLinkGroupType } from './types';
import { useLinksGroup } from 'hooks/useLinksGroup';
import styles from './NavLinksGroup.module.scss';

interface Props {
  mainChild: ReactElement;
  userSideMenuToggler?: ReactElement;
  type: NavLinkGroupType;
  isAuthorizationLinksGroup?: boolean;
  children?: ReactChildrenType;
}

export const NavLinksGroup: FC<Props> = memo(
  ({ mainChild, userSideMenuToggler, children, type, isAuthorizationLinksGroup }) => {
    const linksWrapperRef = useRef(null);
    const { isNavLinksGroupCurrent } = useLinksGroup(type);
    useAnimateNavLinksGroupSliding(isNavLinksGroupCurrent, linksWrapperRef);
    return (
      <div className={styles.NavLinksGroup}>
        <div className={styles.homeButtonWrapper}>
          <HomeButton
            isNavLinksGroupCurrent={isNavLinksGroupCurrent}
            isAuthorizationLinksGroup={isAuthorizationLinksGroup}
          />
          {userSideMenuToggler && userSideMenuToggler}
        </div>
        <div className={styles.linksWrapper} ref={linksWrapperRef}>
          {cloneElement(mainChild, {
            ...mainChild.props,
            isAuthorizationLinksGroup,
          })}
          <div className={styles.secondaryLinksWrapper}>
            {Children.map(children, (child) =>
              cloneElement(child, {
                ...child.props,
                isAuthorizationLinksGroup,
              }),
            )}
          </div>
        </div>
      </div>
    );
  },
);
