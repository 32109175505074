import { FC } from 'react';

import { classNames } from 'helpers/classNames';
import styles from './CustomText.module.scss';
import './CustomText.scss';

type CustomTextType = 'gradient' | 'solid';

interface Props {
  type: CustomTextType;
  text: string;
}

export const CustomText: FC<Props> = ({ type, text }) => {
  return <span className={classNames(styles.CustomText, [type])}>{text}</span>;
};
