import { FC, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { RegistrationQuestionSwitcher } from './components/RegistrationQuestionSwitcher/RegistrationQuestionSwitcher';
import { RegistrationSendReturnButton } from './components/RegistrationSendReturnButton/RegistrationSendReturnButton';
import {
  getRegistrationDataAppliedErrors,
  setCurrentRegistrationQuestionNumber,
} from 'redux/slices/user/exports';
import { RegistrationQuestionNumberType } from '../../types';
import styles from './RegistrationProgressAndSwitcher.module.scss';

export const RegistrationProgressAndSwitcher: FC = memo(() => {
  const dispatch = useAppDispatch();
  const registrationAppliedDataErrors = useAppSelector(getRegistrationDataAppliedErrors);
  useEffect(() => {
    const { email, nickname, password } = registrationAppliedDataErrors;
    if (email || nickname || password) {
      let currentRegistrationQuestionNumber: RegistrationQuestionNumberType = 1;
      switch (true) {
        case nickname:
          currentRegistrationQuestionNumber = 2;
          break;
        case password:
          currentRegistrationQuestionNumber = 3;
      }
      dispatch(setCurrentRegistrationQuestionNumber(currentRegistrationQuestionNumber));
    }
  }, [dispatch, registrationAppliedDataErrors]);
  return (
    <div className={styles.RegistrationProgressAndSwitcher}>
      <RegistrationSendReturnButton />
      <RegistrationQuestionSwitcher questionNumber={1} />
      <RegistrationQuestionSwitcher questionNumber={2} />
      <RegistrationQuestionSwitcher questionNumber={3} />
    </div>
  );
});
