import { FC, memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';

import { getCurrentLanguage, setCurrentlyViewedPage } from 'redux/slices/main/exports';
import { language } from 'utils/language';
import { CurrentlyViewedPageType } from 'redux/types';
import { classNames } from 'helpers/classNames';
import { getIsAuth } from 'redux/slices/user/exports';
import { NavLinkGroupType } from '../../types';
import { useLinksGroup } from 'hooks/useLinksGroup';
import styles from './NavLink.module.scss';

interface Props {
  origin: NavLinkGroupType;
  linkName: string;
  linkSrc: CurrentlyViewedPageType;
  isMainLinkInGroup?: boolean;
  isAppLink?: boolean;
  iconSrc?: string;
  iconAltText?: string;
  isAuthorizationLinksGroup?: boolean;
}

export const NavLink: FC<Props> = memo(
  ({
    origin,
    linkName,
    linkSrc,
    isAppLink,
    isMainLinkInGroup,
    iconSrc,
    iconAltText,
    isAuthorizationLinksGroup,
  }) => {
    const navLinkRef = useRef(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAuth = useAppSelector(getIsAuth);
    const currentLanguage = useAppSelector(getCurrentLanguage);
    const { isNavLinksGroupCurrent, isThisPageCurrent } = useLinksGroup(origin, linkSrc);
    const isAuthorizationHiddenGroup = isAuth && isAuthorizationLinksGroup;
    const isVisible = isMainLinkInGroup || isNavLinksGroupCurrent;
    const isDisplayed = (isThisPageCurrent || isVisible) && !isAuthorizationHiddenGroup;
    const isClickable = !isThisPageCurrent && isDisplayed;

    const changeCurrentlyViewedPage = () => {
      if (!isVisible || (isAuth && isAuthorizationLinksGroup)) return;
      navigate(`/${linkSrc}`);
      dispatch(setCurrentlyViewedPage(linkSrc));
    };
    return (
      <div
        className={classNames(styles.NavLink, [], {
          [styles.appLink]: isAppLink,
          [styles.clickable]: isClickable,
          [styles.selected]: isThisPageCurrent,
          [styles.visible]: isDisplayed,
        })}
        onClick={changeCurrentlyViewedPage}
        ref={navLinkRef}
      >
        <span>{language(currentLanguage, linkName)}</span>
        {iconSrc && (
          <img src={iconSrc} alt={iconAltText ? language(currentLanguage, iconAltText) : ''} />
        )}
      </div>
    );
  },
);
