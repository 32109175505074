import { FC, useRef, memo } from 'react';

import { AVAILABLE_LANGUAGES } from 'constants/constants';
import { LanguageSelector } from './components/LanguageSelector/LanguageSelector';
import styles from './RollDownLanguagesMenu.module.scss';

export const RollDownLanguagesMenu: FC = memo(() => {
  const rollDownLanguagesMenuRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.RollDownLanguagesMenu} ref={rollDownLanguagesMenuRef}>
      {AVAILABLE_LANGUAGES.map((languageData, index) => {
        return <LanguageSelector languageSelectorData={languageData} key={index} />;
      })}
    </div>
  );
});
