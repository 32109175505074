import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import {
  MAIN_LOGO_TEXT_APPEARING_DURATION,
  MAIN_LOGO_TEXT_APPEARING_DELAY,
} from 'components/MainLogoLive/constants';
import { ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateAppearingText = (
  isLogoMode: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const { timeline: appearingTextTimeline } = useGSAPTimeline(false, undefined, scopeElement, []);
  const duration = isLogoMode ? ZERO_GSAP_DURATION : MAIN_LOGO_TEXT_APPEARING_DURATION;
  const delay = isLogoMode ? ZERO_GSAP_DURATION : MAIN_LOGO_TEXT_APPEARING_DELAY;
  appearingTextTimeline &&
    appearingTextTimeline.to(scopeElement.current, {
      opacity: 1,
      duration,
      delay,
    });
};
