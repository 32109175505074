import { FC, memo, useEffect, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { LoginSection } from './components/LoginSection/LoginSection';
import { useLoginData } from './hooks/useLoginData';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import styles from './LoginPage.module.scss';

export const LoginPage: FC = memo(() => {
  const loginPageRef = useRef(null);
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isSignInPageActive = 'signin' === currentlyViewedPage;
  useAnimateAppearing(isSignInPageActive, loginPageRef);
  const { isLoginDataEmpty, clearLoginData } = useLoginData();
  useEffect(() => {
    !isSignInPageActive && !isLoginDataEmpty && clearLoginData();
  }, [clearLoginData, isLoginDataEmpty, isSignInPageActive]);
  return (
    <div className={styles.LoginPage} ref={loginPageRef}>
      <LoginSection />
    </div>
  );
});
