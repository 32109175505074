import { RegistrationDataErrorsType } from 'types/types';

export const getRegistrationErrorType = (
  registrationErrorMessage: string,
): RegistrationDataErrorsType => {
  const errors: RegistrationDataErrorsType = {
    email: false,
    password: false,
    nickname: false,
  };
  if (registrationErrorMessage.includes('email')) {
    errors.email = true;
  }
  if (
    registrationErrorMessage.includes('password') ||
    registrationErrorMessage.includes('пароль')
  ) {
    errors.password = true;
  }
  if (
    registrationErrorMessage.includes('nickname') ||
    registrationErrorMessage.includes('никнейм')
  ) {
    errors.nickname = true;
  }
  return errors;
};
