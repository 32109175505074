import { RegistrationQuestionType } from 'types/types';

export const getRegistrationQuestionText = (type: RegistrationQuestionType) => {
  switch (type) {
    case 'email':
      return 'registrationQuestionEmail';
    case 'password':
      return 'registrationQuestionPassword';
    case 'nickname':
      return 'registrationQuestionNickname';
    case 'check':
      return 'registrationQuestionCheck';
  }
};
