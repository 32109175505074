import { MutableRefObject } from 'react';
import gsap from 'gsap';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { GSAPEasingEnum, ZERO_GSAP_DURATION } from 'constants/gsapConstants';
import { LETTERS_SLIDING_DURATION } from 'components/MainLogoLive/constants';

export const useAnimate = (isLogoMode: boolean, scopeElement: MutableRefObject<HTMLDivElement>) => {
  const {
    utils: { toArray },
  } = gsap;
  const { timeline: appearingTimeline } = useGSAPTimeline(false, undefined, scopeElement, []);
  const letters = toArray('.logo-letter') as Array<HTMLDivElement>;
  const LETTER_D = letters[0];
  const LETTER_E = letters[1];
  const LETTER_U = letters[3];
  const LETTER_Z = letters[4];
  const LETTERS_EASE_TYPE = GSAPEasingEnum.POWER1_OUT;
  const duration = isLogoMode ? ZERO_GSAP_DURATION : LETTERS_SLIDING_DURATION;
  appearingTimeline &&
    appearingTimeline
      .from(LETTER_D, {
        x: -15,
        opacity: 0,
        duration,
        ease: LETTERS_EASE_TYPE,
      })
      .from(
        LETTER_Z,
        {
          x: 15,
          opacity: 0,
          duration,
          ease: LETTERS_EASE_TYPE,
        },
        '<',
      )
      .from(
        [LETTER_E, LETTER_U],
        {
          opacity: 0,
          duration,
          ease: LETTERS_EASE_TYPE,
        },
        '<',
      );
};
