import { useAppSelector } from 'redux/hooks';

import { NavLinkGroupType } from 'components/SystemButtons/components/NavLinksGroup/types';
import { ASSISTANT_GROUP_LINKS, SIGN_IN_GROUP_LINKS, INFO_GROUP_LINKS } from 'constants/constants';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import { CurrentlyViewedPageType } from 'redux/types';

export const useLinksGroup = (type: NavLinkGroupType, iconSrc?: CurrentlyViewedPageType) => {
  const links = (() => {
    switch (type) {
      case 'authorization':
        return SIGN_IN_GROUP_LINKS;
      case 'information':
        return INFO_GROUP_LINKS;
      case 'main-application':
        return ASSISTANT_GROUP_LINKS;
    }
  })();
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isThisPageCurrent = currentlyViewedPage === iconSrc;
  return { isNavLinksGroupCurrent: links.includes(currentlyViewedPage), isThisPageCurrent };
};
