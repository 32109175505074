import { FC, memo } from 'react';

import { RegistrationQuestion } from './components/RegistrationQuestion/RegistrationQuestion';
import { RegistrationProgressAndSwitcher } from './components/RegistrationProgressAndSwitcher/RegistrationProgressAndSwitcher';
import styles from './RegistrationSection.module.scss';

export const RegistrationSection: FC = memo(() => {
  return (
    <form className={styles.RegistrationSection}>
      <RegistrationProgressAndSwitcher />
      <RegistrationQuestion type="email" />
      <RegistrationQuestion type="nickname" />
      <RegistrationQuestion type="password" />
      <RegistrationQuestion type="check" />
    </form>
  );
});
