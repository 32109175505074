import { CurrentLanguageType, RegistrationDataErrorsType, RegistrationDataType } from 'types/types';
import { RegistrationQuestionNumberType } from '../../../../types';
import { language } from 'utils/language';

export const getQuestionSwitcherTitle = (
  questionNumber: RegistrationQuestionNumberType,
  currentLanguage: CurrentLanguageType,
) => {
  switch (questionNumber) {
    case 1:
      return 'email';
    case 2:
      return language(currentLanguage, 'nicknameCap');
    case 3:
      return language(currentLanguage, 'passwordCap');
  }
};

export const getIsPreviousQuestionAnswered = (
  questionNumber: RegistrationQuestionNumberType,
  registrationDataApplied: RegistrationDataType,
): boolean => {
  const { email, nickname } = registrationDataApplied;
  switch (questionNumber) {
    case 1:
      return false;
    case 2:
      return !!email;
    case 3:
      return !!nickname;
  }
};

const transformPasswordIntoStars = (password: string): string => {
  return password
    .split('')
    .map(() => '*')
    .join('');
};

export const getAppliedInfoText = (
  questionNumber: RegistrationQuestionNumberType,
  registrationDataApplied: RegistrationDataType,
  isCurrentQuestion: boolean,
) => {
  const { email, nickname, password } = registrationDataApplied;
  const isPreviousQuestionAnswered = getIsPreviousQuestionAnswered(
    questionNumber,
    registrationDataApplied,
  );
  const isTitleMaybeShown = isCurrentQuestion || isPreviousQuestionAnswered;
  switch (questionNumber) {
    case 1:
      return email ? email : isTitleMaybeShown ? '...' : '';
    case 2:
      return nickname ? nickname : isTitleMaybeShown ? '...' : '';
    case 3:
      return password ? transformPasswordIntoStars(password) : isTitleMaybeShown ? '...' : '';
  }
};

export const getRegistrationDataError = (registrationQuestionNumber: RegistrationQuestionNumberType, registrationAppliedDataErrors: RegistrationDataErrorsType) => {
  const { email, nickname, password } = registrationAppliedDataErrors;
  switch (registrationQuestionNumber) {
    case 1:
      return !!email;
    case 2:
      return !!nickname;
    case 3:
      return !!password;
    default:
      return false;
  }
};
