import { Route, Routes, Navigate } from 'react-router-dom';

import { MainPageRemastered } from 'pages/MainPageRemastered/MainPageRemastered';
import { RoutesEnum } from 'constants/routes';

export const useRoutes = () => {
  return (
    <Routes>
      <Route path={`${RoutesEnum.ROOT_PATH_URL}`} element={<MainPageRemastered />}></Route>
      <Route path={`${RoutesEnum.SPORT_ASSISTANT_URL}`} element={<MainPageRemastered />}></Route>
      <Route path={`${RoutesEnum.SIGN_IN_URL}`} element={<MainPageRemastered />}></Route>
      <Route path={`${RoutesEnum.SIGN_UP_URL}`} element={<MainPageRemastered />}></Route>
      <Route path={`${RoutesEnum.INFO_URL}`} element={<MainPageRemastered />}></Route>
      <Route path={`${RoutesEnum.PROJECTS_URL}`} element={<MainPageRemastered />}></Route>
      <Route path={`${RoutesEnum.SUPPORT_URL}`} element={<MainPageRemastered />}></Route>
      <Route path={'*'} element={<Navigate to={`${RoutesEnum.ROOT_PATH_URL}`} />}></Route>
    </Routes>
  );
};
