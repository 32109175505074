import { FC } from 'react';
import { useAppSelector } from 'redux/hooks';

import { TextStyled } from 'components/TextStyled/TextStyled';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { language } from 'utils/language';
import styles from './NoAccountTextWithLink.module.scss';

export const NoAccountTextWithLink: FC = () => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  return (
    <div className={styles.NoAccountTextWithLink}>
      <TextStyled text={language(currentLanguage, 'translateToSignUpTextWithLink')} />
    </div>
  );
};
