import { FC } from 'react';
import { useAppSelector } from 'redux/hooks';

import { getUserNickname } from 'redux/slices/user/exports';
import styles from './UserSideMenuHead.module.scss';

interface Props {
  closeUserSideMenu: VoidFunction;
}

export const UserSideMenuHead: FC<Props> = ({ closeUserSideMenu }) => {
  const userNickname = useAppSelector(getUserNickname);
  return (
    <div className={styles.UserSideMenuHead}>
      <span className={styles.userNicknameSpan}>{userNickname}</span>
      <span className={styles.closeSideMenuSpan} onClick={closeUserSideMenu}>
        &times;
      </span>
    </div>
  );
};
