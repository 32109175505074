import { FC, memo } from 'react';

import { MainLogoLive } from 'components/MainLogoLive/MainLogoLive';
import styles from './LoginLogo.module.scss';

export const LoginLogo: FC = memo(() => {
  return (
    <div className={styles.LoginLogo}>
      <MainLogoLive isLogoMode />
    </div>
  );
});
