import { FC } from 'react';
import { Link } from 'react-router-dom';

import { getCanLinkBeDispatched } from './utils';
import { useAppDispatch } from 'redux/hooks';
import { setCurrentlyViewedPage } from 'redux/slices/main/exports';
import { CurrentlyViewedPageType } from 'redux/types';
import styles from './CustomLink.module.scss';

interface Props {
  linkText: string;
  linkUrl: string;
}

export const CustomLink: FC<Props> = ({ linkText, linkUrl }) => {
  const dispatch = useAppDispatch();
  const canLinkBeDispatched = getCanLinkBeDispatched(linkUrl.slice(1));
  const dispatchPage = () =>
    dispatch(setCurrentlyViewedPage(linkUrl.slice(1) as CurrentlyViewedPageType));
  return (
    <Link
      to={linkUrl}
      className={styles.CustomLink}
      onClick={canLinkBeDispatched ? dispatchPage : undefined}
    >
      {linkText}
    </Link>
  );
};
