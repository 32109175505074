import { IObjectOfObjects } from 'types/types';
import { ITranslationsObject } from './index';

export const IMAGES_ALT_TEXT_TRANSLATIONS: IObjectOfObjects<ITranslationsObject> = {
  roundedUpArrow: {
    en: 'rounded up arrow',
    ru: 'скруглённая стрелка вверх',
  },
  heart: {
    en: 'a heart (love)',
    ru: 'сердце (любовь)',
  },
};
