import { MutableRefObject } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { PAGE_CHANGE_DURATION, ZERO_GSAP_DURATION } from 'constants/gsapConstants';
import { getIsAuth } from 'redux/slices/user/exports';

export const useAnimateHighlighting = (
  isNavLinksGroupCurrent: boolean,
  isAuthorizationLinksGroup: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const isAuth = useAppSelector(getIsAuth);
  const isAuthorizationHiddenGroup = isAuth && isAuthorizationLinksGroup;
  const cursorType = !isNavLinksGroupCurrent || isAuthorizationHiddenGroup ? 'default' : 'pointer';
  const displayType = isAuthorizationHiddenGroup ? 'none' : 'flex';
  const opacity = !isNavLinksGroupCurrent || isAuthorizationHiddenGroup ? 0 : 1;
  const {
    timeline: highlightingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [
    isNavLinksGroupCurrent,
    isAuthorizationLinksGroup,
    isAuth,
  ]);
  const duration = isFirstLoad
    ? ZERO_GSAP_DURATION
    : !isNavLinksGroupCurrent
    ? PAGE_CHANGE_DURATION / 2
    : isAuthorizationHiddenGroup
    ? PAGE_CHANGE_DURATION
    : PAGE_CHANGE_DURATION * 2;
  const delay =
    isFirstLoad || !isNavLinksGroupCurrent || isAuthorizationHiddenGroup
      ? ZERO_GSAP_DURATION
      : PAGE_CHANGE_DURATION * 2;
  highlightingTimeline &&
    highlightingTimeline
      .to(scopeElement.current, {
        opacity,
        display: displayType,
        duration,
        delay,
        onComplete: () => setIsFirstLoad(false),
      })
      .to(
        scopeElement.current,
        {
          cursor: cursorType,
          duration: ZERO_GSAP_DURATION,
        },
        '<',
      );
};
