import { FC, memo, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';

import { HomePage } from './pages/HomePage/HomePage';
import { getCurrentlyViewedPage, setCurrentlyViewedPage } from 'redux/slices/main/exports';
import { AssistantPage } from './pages/AssistantPage/AssistantPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { RegistrationPage } from './pages/RegistrationPage/RegistrationPage';
import { InfoPage } from './pages/InfoPage/InfoPage';
import { SupportPage } from './pages/SupportPage/SupportPage';
import { ProjectsPage } from './pages/ProjectsPage/ProjectsPage';
import { getIsAuth } from 'redux/slices/user/exports';
import { RoutesEnum } from 'constants/routes';
import { UserSideMenu } from './components/UserSideMenu/UserSideMenu';
import styles from './MainPageRemastered.module.scss';

export const MainPageRemastered: FC = memo(() => {
  const minPageRemasteredRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuth = useAppSelector(getIsAuth);
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isAuthRoute = currentlyViewedPage === 'signin' || currentlyViewedPage === 'signup';
  const shouldAuthRouteBeSwitched = isAuthRoute && isAuth;
  useEffect(() => {
    if (shouldAuthRouteBeSwitched) {
      navigate(RoutesEnum.SPORT_ASSISTANT_URL, { replace: true });
      dispatch(setCurrentlyViewedPage('assistant'));
    }
  }, [dispatch, navigate, shouldAuthRouteBeSwitched]);
  return (
    <div className={styles.MainPageRemastered} ref={minPageRemasteredRef}>
      <UserSideMenu />
      <HomePage />
      <AssistantPage />
      <LoginPage />
      <RegistrationPage />
      <InfoPage />
      <SupportPage />
      <ProjectsPage />
    </div>
  );
});
