import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { PAGE_CHANGE_DURATION, ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateAppearingUserSideMenu = (
  isOpened: boolean,
  background: MutableRefObject<HTMLDivElement>,
  menu: MutableRefObject<HTMLDivElement>,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const {
    timeline: appearingUserSideMenuTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isOpened]);
  const displayType = isOpened ? 'flex' : 'none';
  const menuXPosition = isOpened ? 0 : '100%';
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION / 1.5;
  appearingUserSideMenuTimeline &&
    appearingUserSideMenuTimeline
      .to(scopeElement.current, {
        display: displayType,
        duration: duration * 1.3,
        onComplete: () => {
          isFirstLoad && setIsFirstLoad(false);
        },
      })
      .to(
        menu.current,
        {
          x: menuXPosition,
          duration,
          delay: isOpened ? duration : ZERO_GSAP_DURATION,
        },
        '<',
      );
  const backgroundColor = isOpened ? 'rgba(9, 9, 9, 0.65)' : 'rgba(9, 9, 9, 0.0)';
  const { timeline: appearingUserSideMenuBackgroundTimeline } = useGSAPTimeline(
    false,
    undefined,
    scopeElement,
    [isOpened],
  );
  appearingUserSideMenuBackgroundTimeline &&
    appearingUserSideMenuBackgroundTimeline.to(background.current, {
      backgroundColor,
      duration,
      delay: !isOpened ? duration : ZERO_GSAP_DURATION,
    });
};
