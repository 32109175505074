import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { EMPTY_STRING } from 'constants/constants';
import { getLoginData, setLoginData } from 'redux/slices/user/exports';
import { LOGIN_DATA_DEFAULTS } from 'redux/slices/user/constants';

export const useLoginData = () => {
  const dispatch = useAppDispatch();
  const loginData = useAppSelector(getLoginData);
  const isLoginDataEmpty = Object.values(loginData).every((value) => value === EMPTY_STRING);
  const clearLoginData = () => dispatch(setLoginData(LOGIN_DATA_DEFAULTS));
  return {
    clearLoginData,
    loginData,
    isLoginDataEmpty,
  };
};
