import { FC, memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { HOME_LOGO_SRC } from 'constants/assets-src';
import { getCurrentlyViewedPage, setCurrentlyViewedPage } from 'redux/slices/main/exports';
import { useAnimateHighlighting } from './hooks/useAnimate';
import { RoutesEnum } from 'constants/routes';
import { getIsAuth } from 'redux/slices/user/exports';
import styles from './HomeButton.module.scss';

interface Props {
  isNavLinksGroupCurrent: boolean;
  isAuthorizationLinksGroup: boolean;
}

export const HomeButton: FC<Props> = memo(
  ({ isNavLinksGroupCurrent, isAuthorizationLinksGroup }) => {
    const homeButtonRef = useRef(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAuth = useAppSelector(getIsAuth);
    const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
    useAnimateHighlighting(isNavLinksGroupCurrent, isAuthorizationLinksGroup, homeButtonRef);

    const changeCurrentlyViewedPageToHome = () => {
      if (!currentlyViewedPage || (isAuth && isAuthorizationLinksGroup)) return;
      navigate(RoutesEnum.ROOT_PATH_URL);
      dispatch(setCurrentlyViewedPage('home'));
    };
    return (
      <div
        className={styles.HomeButton}
        onClick={changeCurrentlyViewedPageToHome}
        ref={homeButtonRef}
      >
        <img draggable={false} src={HOME_LOGO_SRC} alt="a house with a triangle roof" />
      </div>
    );
  },
);
