import { FC, ReactNode, useRef, Children, cloneElement } from 'react';

import { useAnimate } from './hooks/useAnimate';
import { ReactChildrenType } from 'types/types';
import styles from './MainLogoLettersWrapper.module.scss';

interface Props {
  children?: ReactChildrenType;
  isLogoMode?: boolean;
}

export const MainLogoLettersWrapper: FC<Props> = ({ children, isLogoMode }) => {
  const mainLogoLettersWrapperRef = useRef<HTMLDivElement>(null);
  useAnimate(isLogoMode, mainLogoLettersWrapperRef);
  return (
    <div className={styles.MainLogoLettersWrapper} ref={mainLogoLettersWrapperRef}>
      {children &&
        Children.map(
          children,
          (child) =>
            child &&
            cloneElement(child, {
              isLogoMode,
            }),
        )}
    </div>
  );
};
