import { FC } from 'react';

import { classNames } from 'helpers/classNames';
import styles from './MainLogoLetter.module.scss';

interface Props {
  letterData: string;
  isLogoMode?: boolean;
}

export const MainLogoLetter: FC<Props> = ({ letterData, isLogoMode }) => {
  return (
    <div
      className={classNames(styles.MainLogoLetter, ['logo-letter'], {
        [styles.logoMode]: isLogoMode,
      })}
    >
      <span>{letterData}</span>
    </div>
  );
};
