import { EMPTY_STRING } from 'constants/constants';
import {
  RegistrationDataErrorsType,
  RegistrationDataType,
  RegistrationQuestionType,
} from 'types/types';

export const getRegistrationQuestionNumberFromType = (type: RegistrationQuestionType): number => {
  switch (type) {
    case 'email':
      return 1;
    case 'nickname':
      return 2;
    case 'password':
      return 3;
    case 'check':
      return 4;
    default:
      return 1;
  }
};

export const getIsReadyToSendRegistrationData = (
  registrationDataApplied: RegistrationDataType,
  registrationAppliedDataErrors: RegistrationDataErrorsType,
) => {
  if (Object.values(registrationAppliedDataErrors).some((value) => value === true)) return false;
  return Object.values(registrationDataApplied).every((value) => value !== EMPTY_STRING);
};
