import { MutableRefObject } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { PAGE_CHANGE_DURATION, ZERO_GSAP_DURATION } from 'constants/gsapConstants';
import { getIsAuth } from 'redux/slices/user/exports';

export const useAnimateAppearing = (scopeElement: MutableRefObject<HTMLDivElement>) => {
  const isAuth = useAppSelector(getIsAuth);
  const cursorType = !isAuth ? 'default' : 'pointer';
  const {
    timeline: appearingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isAuth]);
  const opacity = isAuth ? 1 : 0;
  const displayType = isAuth ? 'flex' : 'none';
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  const delay = isFirstLoad || !isAuth ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  appearingTimeline &&
    appearingTimeline
      .to(scopeElement.current, {
        opacity,
        display: displayType,
        duration,
        delay,
        onComplete: () => {
          isFirstLoad && setIsFirstLoad(false);
        },
      })
      .to(
        scopeElement.current,
        {
          cursor: cursorType,
          duration: ZERO_GSAP_DURATION,
        },
        '<',
      );
};
