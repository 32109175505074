import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { ZERO_GSAP_DURATION } from 'constants/gsapConstants';
import { REGISTRATION_QUESTION_ANIMATION_BASE_DURATION } from 'pages/MainPageRemastered/pages/RegistrationPage/components/RegistrationSection/components/RegistrationQuestion/constants';

export const useAnimateErrorAppearing = (
  hasError: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const opacity = !hasError ? 0 : 0.95;
  const {
    timeline: errorAppearingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [hasError]);
  const duration =
    isFirstLoad || !hasError ? ZERO_GSAP_DURATION : REGISTRATION_QUESTION_ANIMATION_BASE_DURATION;
  errorAppearingTimeline &&
    errorAppearingTimeline.to(scopeElement.current, {
      opacity,
      duration,
      onComplete: () => {
        isFirstLoad && setIsFirstLoad(false);
      },
    });
};
