import { IObjectOfObjects } from 'types/types';
import { MAIN_TRANSLATIONS } from './main-translations';
import { IMAGES_ALT_TEXT_TRANSLATIONS } from './images-alt-text-translations';

export interface ITranslationsObject {
  en: string;
  ru: string;
}

export const TRANSLATIONS: IObjectOfObjects<ITranslationsObject> = {
  ...MAIN_TRANSLATIONS,
  ...IMAGES_ALT_TEXT_TRANSLATIONS,
};
