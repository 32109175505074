import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getLoginData } from 'redux/slices/user/exports';
import { userLoginAsync } from '../userThunks';
import { getCurrentLanguage } from 'redux/slices/main/exports';

export const useThunks = () => {
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const loginData = useAppSelector(getLoginData);

  const login = () => dispatch(userLoginAsync({ ...loginData, lang: currentLanguage }));
  return { login };
};
