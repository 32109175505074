import { MutableRefObject } from 'react';
import gsap from 'gsap';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { SYSTEM_MESSAGE_LETTERS_STAGING } from '../constants';

export const useAnimateTextAppearing = (
  isShown: boolean,
  clearMessageData: VoidFunction,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const {
    utils: { toArray },
  } = gsap;
  const letters = toArray('.notification-text-letter') as Array<HTMLSpanElement>;
  const opacityValue = isShown ? 1 : 0;
  const { timeline: textAppearingTimeline } = useGSAPTimeline(false, undefined, scopeElement, [
    isShown,
  ]);
  letters.length &&
    textAppearingTimeline &&
    textAppearingTimeline.to(letters, {
      opacity: opacityValue,
      stagger: {
        amount: SYSTEM_MESSAGE_LETTERS_STAGING,
        from: isShown ? 'start' : 'end',
      },
      ease: 'elastic',
      onComplete: () => {
        !isShown && clearMessageData();
      },
    });
};
