import { FC, memo, useRef } from 'react';

import { useAnimateHighlighting } from './hooks/useAnimate';
import { classNames } from 'helpers/classNames';
import styles from './ButtonRemastered.module.scss';

interface Props {
  isDisabled?: boolean;
  onClick?: VoidFunction;
  buttonText?: string;
  isWideButton?: boolean;
  isFullWidth?: boolean;
}

export const ButtonRemastered: FC<Props> = memo(
  ({ isDisabled, onClick, buttonText, isWideButton, isFullWidth }) => {
    const registrationApplyButtonRef = useRef<HTMLDivElement>(null);
    const registrationApplyButtonTextRef = useRef<HTMLSpanElement>(null);
    useAnimateHighlighting(isDisabled, registrationApplyButtonRef, registrationApplyButtonTextRef);
    return (
      <div
        role="button"
        className={classNames(styles.ButtonRemastered, [], {
          [styles.wideButton]: isWideButton,
          [styles.fullWidth]: isFullWidth,
        })}
        onClick={onClick}
        ref={registrationApplyButtonRef}
      >
        <span ref={registrationApplyButtonTextRef}>{buttonText}</span>
      </div>
    );
  },
);
