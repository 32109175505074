import { AVAILABLE_LANGUAGES } from 'constants/constants';
import { TRANSLATIONS } from 'constants/translations';
import { CurrentLanguageType } from 'types/types';

export const language = (requestLanguage: CurrentLanguageType, wordOrPhrase: string) => {
  return (
    TRANSLATIONS?.[wordOrPhrase]?.[
      AVAILABLE_LANGUAGES.find((rightLanguage) => rightLanguage === requestLanguage)
        ? requestLanguage
        : 'en'
    ] || 'no translation :('
  );
};
