// redux types
export type RequestRESTMethodType = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
export type RequestStatusType = 'idle' | 'loading' | 'failed';
export type CurrentlyViewedPageType =
  | 'home'
  | 'assistant'
  | 'signin'
  | 'signup'
  | 'info'
  | 'support'
  | 'projects';

// redux enums
export enum RequestRESTMethodsEnum {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}
