import { FC, memo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { getIsAuth } from 'redux/slices/user/exports';
import { AssistantIntroduction } from './components/AssistantIntroduction/AssistantIntroduction';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import styles from './AssistantPage.module.scss';

export const AssistantPage: FC = memo(() => {
  const assistantPageRef = useRef(null);
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isAuth = useAppSelector(getIsAuth);
  const isComponentActive = 'assistant' === currentlyViewedPage;
  useAnimateAppearing(isComponentActive, assistantPageRef);
  return (
    <div className={styles.AssistantPage} ref={assistantPageRef}>
      {isAuth ? null : <AssistantIntroduction />}
    </div>
  );
});
