import { FC, memo } from 'react';
import { useAppSelector } from 'redux/hooks';

import { OPENED_EYE_ICON_SRC, CLOSED_EYE_ICON_SRC } from 'constants/assets-src';
import { language } from 'utils/language';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import styles from './PasswordVisibilitySwitcher.module.scss';

interface Props {
  isDisplayed: boolean;
  isPasswordShown: boolean;
  onClickToggler: VoidFunction;
}

export const PasswordVisibilitySwitcher: FC<Props> = memo(
  ({ isDisplayed, isPasswordShown, onClickToggler }) => {
    const currentLanguage = useAppSelector(getCurrentLanguage);
    const passwordVisibilityIconSrc = isPasswordShown ? OPENED_EYE_ICON_SRC : CLOSED_EYE_ICON_SRC;
    const passwordVisibilityIconAlternateText = language(
      currentLanguage,
      isPasswordShown ? 'openEye' : 'closedEye',
    );
    if (!isDisplayed) return null;
    return (
      <img
        role="button"
        className={styles.PasswordVisibilitySwitcher}
        src={passwordVisibilityIconSrc}
        alt={passwordVisibilityIconAlternateText}
        onClick={onClickToggler}
      />
    );
  },
);
