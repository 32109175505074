import { EMPTY_STRING } from 'constants/constants';
import { UserState } from './types';
import {
  LOGIN_DATA_DEFAULTS,
  REGISTRATION_APPLIED_DATA_ERRORS_DEFAULTS,
  REGISTRATION_DATA_DEFAULTS,
} from './constants';

export const userSliceInitialState: UserState = {
  isAuth: false,
  token: null,
  userId: null,
  userEmail: null,
  userNickname: null,
  userRole: null,
  isDoctor: false,
  isPatient: false,
  isPetOwner: false,
  isVeterinarian: false,
  isSportsmen: false,
  isTrainer: false,
  userBirthDateTimestamp: null,
  userCity: null,
  userCountry: null,
  userFirstName: null,
  userLastName: null,
  userAvatar: undefined,
  isEmailConfirmed: false,
  userRestrictionsLevel: undefined,
  userRestrictionsTimestamp: undefined,
  selectedRegistrationEmail: EMPTY_STRING,
  authMessage: null,
  isEmailChecking: false,
  authStatus: 'idle',
  updateStatus: 'idle',
  processingStatus: 'idle',
  recoveryCodeTimerTimestamp: null,
  recoverSessionTimerTimestamp: null,
  recoverySessionId: null,
  myAssessments: [],
  assessmentsToApprove: [],
  feedbacks: [],
  repeatedPassword: EMPTY_STRING,
  registrationData: REGISTRATION_DATA_DEFAULTS,
  registrationDataApplied: REGISTRATION_DATA_DEFAULTS,
  registrationDataAppliedErrors: REGISTRATION_APPLIED_DATA_ERRORS_DEFAULTS,
  currentRegistrationQuestionNumber: 1,
  isRegistrationMayProceed: false,
  loginData: LOGIN_DATA_DEFAULTS,
};
