import { LoginDataType, RegistrationDataErrorsType, RegistrationDataType } from 'types/types';

export const REGISTRATION_DATA_DEFAULTS: RegistrationDataType = {
  email: '',
  password: '',
  nickname: '',
};

export const REGISTRATION_APPLIED_DATA_ERRORS_DEFAULTS: RegistrationDataErrorsType = {
  email: false,
  password: false,
  nickname: false,
};

export const LOGIN_DATA_DEFAULTS: LoginDataType = {
  email: '',
  password: '',
};
