import { useAppSelector, useAppDispatch } from '../redux/hooks';

import { userAuthCheckAsync } from '../redux/thunks/userThunks';
import { ILocalStorageSave, Nullable } from 'types/types';
import { getIsFirstLoad, getCurrentLanguage, setIsFirstLoad } from 'redux/slices/main/exports';

export const useLocalStorageLoad = () => {
  const dispatch = useAppDispatch();
  const isFirstLoad = useAppSelector(getIsFirstLoad);
  const currentLanguage = useAppSelector(getCurrentLanguage);
  return () => {
    if (isFirstLoad) {
      const save: Nullable<string> = localStorage.getItem('deguz-save');
      if (save) {
        try {
          const saveData: ILocalStorageSave = JSON.parse(save);
          saveData?.token &&
            dispatch(
              userAuthCheckAsync({
                lang: currentLanguage,
                currentTokenObject: { currentToken: saveData.token },
              }),
            );
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.error(error);
          }
        }
      }
      dispatch(setIsFirstLoad(false));
    }
  };
};
