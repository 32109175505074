import { FC, memo, useRef } from 'react';

import { MainLogoLetter } from './components/MainLogoLetter/MainLogoLetter';
import { MainLogoLettersWrapper } from './components/MainLogoLettersWrapper/MainLogoLettersWrapper';
import { MainLogoText } from './components/MainLogoText/MainLogoText';
import styles from './MainLogoLive.module.scss';

interface Props {
  isLogoMode?: boolean;
}

export const MainLogoLive: FC<Props> = memo(({ isLogoMode }) => {
  const mainLogoLiveRef = useRef<HTMLDivElement>();
  return (
    <div className={styles.MainLogoLive} ref={mainLogoLiveRef}>
      <MainLogoLettersWrapper isLogoMode={isLogoMode}>
        <MainLogoLetter letterData={'D'} />
        <MainLogoLetter letterData={'E'} />
        <MainLogoLetter letterData={'G'} />
        <MainLogoLetter letterData={'U'} />
        <MainLogoLetter letterData={'Z'} />
      </MainLogoLettersWrapper>
      <MainLogoText isLogoMode={isLogoMode} />
    </div>
  );
});
