import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import {
  MAIN_THEME_COLOR,
  NEGATIVE_COLOR,
  PAGE_CHANGE_DURATION,
  SECONDARY_TEXT_COLOR,
  TEXT_COLOR_DARK,
  ZERO_GSAP_DURATION,
} from 'constants/gsapConstants';
import { useAppSelector } from 'redux/hooks';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';

export const useAnimateExtension = (
  isCurrentQuestionOrAlreadyAnswered: boolean,
  hasRegistrationDataError: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isOnRegistrationPage = currentlyViewedPage === 'signup';
  const width = !isCurrentQuestionOrAlreadyAnswered ? 0 : 100;
  const backgroundColor = hasRegistrationDataError ? NEGATIVE_COLOR : MAIN_THEME_COLOR;
  const {
    timeline: extensionTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [
    isCurrentQuestionOrAlreadyAnswered,
    hasRegistrationDataError,
  ]);
  const duration = isFirstLoad || !isOnRegistrationPage ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  extensionTimeline &&
    extensionTimeline.to(scopeElement.current, {
      width: `${width}%`,
      backgroundColor: backgroundColor,
      duration,
      onComplete: () => {
        isFirstLoad && setIsFirstLoad(false);
      },
    });
};

export const useAnimateShadowing = (
  isCurrentQuestion: boolean,
  hasRegistrationDataError: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const boxShadowColor = hasRegistrationDataError ? NEGATIVE_COLOR : MAIN_THEME_COLOR;
  const boxShadow = isCurrentQuestion
    ? `0 0 10px 2px ${boxShadowColor}`
    : `0 0 0px 0px ${boxShadowColor}`;
  const {
    timeline: shadowingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [
    isCurrentQuestion,
    hasRegistrationDataError,
  ]);
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  shadowingTimeline &&
    shadowingTimeline.to(scopeElement.current, {
      boxShadow,
      duration,
      onComplete: () => {
        isFirstLoad && setIsFirstLoad(false);
      },
    });
};

export const useAnimateHighlighting = (
  isCurrentQuestion: boolean,
  scopeElement: MutableRefObject<HTMLParagraphElement>,
) => {
  const textColor = isCurrentQuestion ? TEXT_COLOR_DARK : SECONDARY_TEXT_COLOR;
  const {
    timeline: highlightingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isCurrentQuestion]);
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  highlightingTimeline &&
    highlightingTimeline.to(scopeElement.current, {
      color: textColor,
      duration,
      onComplete: () => {
        isFirstLoad && setIsFirstLoad(false);
      },
    });
};
