import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { ZERO_GSAP_DURATION } from 'constants/gsapConstants';
import {
  REGISTRATION_APPLY_BUTTON_BG_COLOR_DISABLED,
  REGISTRATION_APPLY_BUTTON_BG_COLOR,
  REGISTRATION_APPLY_BUTTON_TEXT_COLOR_DISABLED,
  REGISTRATION_APPLY_BUTTON_TEXT_COLOR,
  REGISTRATION_QUESTION_ANIMATION_BASE_DURATION,
} from 'pages/MainPageRemastered/pages/RegistrationPage/components/RegistrationSection/components/RegistrationQuestion/constants';
export const useAnimateHighlighting = (
  isDisabled: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
  textChildElement: MutableRefObject<HTMLSpanElement>,
) => {
  const {
    timeline: highlightingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isDisabled]);
  const cursor = isDisabled ? 'default' : 'pointer';
  const buttonBackgroundColor = isDisabled
    ? REGISTRATION_APPLY_BUTTON_BG_COLOR_DISABLED
    : REGISTRATION_APPLY_BUTTON_BG_COLOR;
  const textColor = isDisabled
    ? REGISTRATION_APPLY_BUTTON_TEXT_COLOR_DISABLED
    : REGISTRATION_APPLY_BUTTON_TEXT_COLOR;
  const boxShadow = isDisabled
    ? `inset 0 0 4px ${REGISTRATION_APPLY_BUTTON_TEXT_COLOR_DISABLED}`
    : `inset 0 0 3px ${REGISTRATION_APPLY_BUTTON_TEXT_COLOR}`;
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : REGISTRATION_QUESTION_ANIMATION_BASE_DURATION;
  highlightingTimeline &&
    highlightingTimeline
      .to(scopeElement.current, {
        backgroundColor: buttonBackgroundColor,
        boxShadow,
        duration,
        onComplete: () => {
          isFirstLoad && setIsFirstLoad(false);
        },
      })
      .to(
        textChildElement.current,
        {
          color: textColor,
          duration,
        },
        '<',
      )
      .to(
        scopeElement.current,
        {
          cursor,
          duration: ZERO_GSAP_DURATION,
        },
        '<',
      );
};
