import { FC, memo } from 'react';

import { ReactChildrenType } from 'types/types';
import styles from './LoginForm.module.scss';

interface Props {
  children?: ReactChildrenType;
}

export const LoginForm: FC<Props> = memo(({ children }) => {
  return <form className={styles.LoginForm}>{children}</form>;
});
