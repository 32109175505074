import { EMPTY_STRING } from 'constants/constants';
import { PAGE_CHANGE_DURATION } from 'constants/gsapConstants';
import { ISystemMessageObject } from 'types/types';

export const MESSAGE_DATA_DEFAULTS: ISystemMessageObject = {
  message: EMPTY_STRING,
  severity: 'neutral',
};

export const SYSTEM_MESSAGE_LETTERS_STAGING = PAGE_CHANGE_DURATION * 1.5;
