import { FC, useRef } from 'react';

import { useAnimateAppearingText } from './hooks/useAnimate';
import { classNames } from 'helpers/classNames';
import styles from './MainLogoText.module.scss';

interface Props {
  isLogoMode?: boolean;
}

export const MainLogoText: FC<Props> = ({ isLogoMode }) => {
  const mainLogoTextRef = useRef(null);
  useAnimateAppearingText(isLogoMode, mainLogoTextRef);
  return (
    <p
      className={classNames(styles.MainLogoText, [], { [styles.logoMode]: isLogoMode })}
      ref={mainLogoTextRef}
    >
      <span>software</span>
    </p>
  );
};
