import { FC, memo, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getIsUserSideMenuOpened, setIsUserSideMenuOpened } from 'redux/slices/main/exports';
import { useAnimateAppearingUserSideMenu } from './hooks/useAnimate';
import { UserSideMenuContainer } from './components/UserSideMenuContainer';
import { UserSideMenuHead } from './components/components/UserSideMenuHead/UserSideMenuHead';
import { UserSideMenuSectionSeparator } from './components/components/UserSideMenuSectionSeparator/UserSideMenuSectionSeparator';
import styles from './UserSideMenu.module.scss';

export const UserSideMenu: FC = memo(() => {
  const userSideMenuRef = useRef(null);
  const dispatch = useAppDispatch();
  const userSideMenuBackgroundRef = useRef(null);
  const userSideMenuContainerRef = useRef(null);
  const isUserSideMenuOpened = useAppSelector(getIsUserSideMenuOpened);
  useAnimateAppearingUserSideMenu(
    isUserSideMenuOpened,
    userSideMenuBackgroundRef,
    userSideMenuContainerRef,
    userSideMenuRef,
  );

  const closeUserSideMenu = useCallback(() => {
    dispatch(setIsUserSideMenuOpened(false));
  }, [dispatch]);
  return (
    <div className={styles.UserSideMenu} ref={userSideMenuRef}>
      <div
        className={styles.background}
        ref={userSideMenuBackgroundRef}
        onClick={closeUserSideMenu}
      ></div>
      <UserSideMenuContainer ref={userSideMenuContainerRef}>
        <UserSideMenuHead closeUserSideMenu={closeUserSideMenu} />
        <UserSideMenuSectionSeparator />
      </UserSideMenuContainer>
    </div>
  );
});
