import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';

import { getCurrentLanguage } from 'redux/slices/main/exports';
import {
  getRegistrationData,
  getRegistrationDataApplied,
  getRegistrationDataAppliedErrors,
  setCurrentRegistrationQuestionNumber,
  setRegistrationDataApplied,
  setRegistrationDataAppliedErrors,
} from 'redux/slices/user/exports';
import {
  EnterPressVoidMethod,
  Nullable,
  RegistrationDataErrorsType,
  RegistrationDataType,
  RegistrationQuestionType,
} from 'types/types';
import { RegistrationQuestionNumberType } from '../../../../../types';
import { getRegistrationQuestionNumberFromType } from '../../../../../utils';
import { language } from 'utils/language';
import { userRegistrationAsync } from 'redux/thunks/userThunks';
import { getGeneralAppStatus } from 'redux/reselect/reselect';
import { REGISTRATION_APPLIED_DATA_ERRORS_DEFAULTS } from 'redux/slices/user/constants';

interface IRegistrationApplyData {
  onClick: Nullable<VoidFunction>;
  onEnterPress: Nullable<EnterPressVoidMethod>;
  isDisabled: boolean;
  registrationApplyButtonText: string;
  registrationData: RegistrationDataType;
  registrationAppliedData: RegistrationDataType;
  registrationAppliedDataErrors: RegistrationDataErrorsType;
  isLoading: boolean;
}

export const useRegistrationApply = (
  type: RegistrationQuestionType,
  validationError: string,
  isValidatedFieldEmpty: boolean,
): IRegistrationApplyData => {
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isLoading = useAppSelector(getGeneralAppStatus) === 'loading';
  const registrationQuestionNumber = getRegistrationQuestionNumberFromType(type);
  const isFinalQuestion = type === 'check';
  const registrationData = useAppSelector(getRegistrationData);
  const registrationFieldData = !isFinalQuestion ? registrationData[type] : null;
  const registrationAppliedData = useAppSelector(getRegistrationDataApplied);
  const registrationAppliedDataErrors = useAppSelector(getRegistrationDataAppliedErrors);
  const registrationAppliedFieldData = !isFinalQuestion ? registrationAppliedData[type] : null;
  const isRegistrationAppliedDataNotChanged =
    registrationFieldData === registrationAppliedFieldData;
  const registrationDataApplied = useAppSelector(getRegistrationDataApplied);
  const nextRegistrationQuestionNumber = (registrationQuestionNumber +
    1) as RegistrationQuestionNumberType;
  const isRegistrationQuestionAlreadyAnswered = !!registrationAppliedFieldData;
  const isDisabled =
    (!!validationError ||
      isValidatedFieldEmpty ||
      isRegistrationAppliedDataNotChanged ||
      isLoading) &&
    !isFinalQuestion;
  const registrationApplyButtonText = ((): string => {
    if (isFinalQuestion) return language(currentLanguage, 'sendCap');
    if (isRegistrationQuestionAlreadyAnswered) return 'OK';
    return language(currentLanguage, 'nextCap');
  })();

  if (isDisabled)
    return {
      onClick: null,
      onEnterPress: null,
      isDisabled: true,
      registrationApplyButtonText,
      registrationData,
      registrationAppliedData,
      registrationAppliedDataErrors,
      isLoading,
    };

  const onClick = () => {
    if (isLoading) return;
    if (isFinalQuestion) {
      const { email, nickname, password } = registrationAppliedData;
      dispatch(userRegistrationAsync({ lang: currentLanguage, email, nickname, password }));
    }
    !isFinalQuestion && registrationAppliedDataErrors[type] &&
      dispatch(setRegistrationDataAppliedErrors(REGISTRATION_APPLIED_DATA_ERRORS_DEFAULTS));
    dispatch(
      setRegistrationDataApplied({ ...registrationDataApplied, [type]: registrationFieldData }),
    );
    !isFinalQuestion && !isRegistrationQuestionAlreadyAnswered &&
      dispatch(setCurrentRegistrationQuestionNumber(nextRegistrationQuestionNumber));
  };
  const onEnterPress: EnterPressVoidMethod = (event) => {
    if (isFinalQuestion || isLoading) return;
    if (event.key !== 'Enter') return;
    onClick();
  };
  return {
    onClick,
    onEnterPress,
    isDisabled: false,
    registrationApplyButtonText,
    registrationData,
    registrationAppliedData,
    registrationAppliedDataErrors,
    isLoading,
  };
};
