import { createSelector } from '@reduxjs/toolkit';

import { getAuthStatus } from 'redux/slices/user/exports';
import { getAppLoadingStatus } from 'redux/slices/main/exports';
import { RequestStatusType } from 'redux/types';

export const getGeneralAppStatus = createSelector(
  [getAuthStatus, getAppLoadingStatus],
  (authStatus, appLoadingStatus): RequestStatusType => {
    return authStatus === 'loading' || appLoadingStatus === 'loading' ? 'loading' : 'idle';
  },
);
