import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IAddFeedbackRequestData,
  IDeleteOneFeedbackRequestData,
  IGeneralRequestWithAuth,
  IGeneralResponse,
  IGetAllFeedbacksResponse,
} from './types';
import { Nullable, Undefinable } from 'types/types';
import { universalFetch } from 'redux/utils';
import {
  ADD_FEEDBACK_URL,
  DELETE_ALL_FEEDBACKS_URL,
  DELETE_ONE_FEEDBACK_URL,
  GET_ALL_FEEDBACKS_URL,
} from './constants';
import { RequestRESTMethodsEnum } from 'redux/types';

// get all feedbacks
export const getAllFeedbacksAsync = createAsyncThunk(
  'main/feedback/get-all',
  async (data: IGeneralRequestWithAuth): Promise<Nullable<IGetAllFeedbacksResponse>> => {
    const { lang, token } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const getAllFeedbacksResponse: Undefinable<Response> = await universalFetch(
      `${GET_ALL_FEEDBACKS_URL}?${params}`,
      RequestRESTMethodsEnum.GET,
      undefined,
      token,
    );
    if (getAllFeedbacksResponse) {
      const { status } = getAllFeedbacksResponse;
      const getAllFeedbacksResponseData =
        (await getAllFeedbacksResponse.json()) as IGetAllFeedbacksResponse;
      getAllFeedbacksResponseData.statusCode = status;
      return getAllFeedbacksResponseData;
    }
    return null;
  },
);

// add one feedback
export const addOneFeedbackAsync = createAsyncThunk(
  'main/feedback/add',
  async (data: IAddFeedbackRequestData): Promise<Nullable<IGeneralResponse>> => {
    const { lang, requestData } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const addOneFeedbackResponse: Undefinable<Response> = await universalFetch(
      `${ADD_FEEDBACK_URL}?${params}`,
      RequestRESTMethodsEnum.POST,
      JSON.stringify(requestData),
    );
    if (addOneFeedbackResponse) {
      const { status } = addOneFeedbackResponse;
      const addOneFeedbackResponseData = (await addOneFeedbackResponse.json()) as IGeneralResponse;
      addOneFeedbackResponseData.statusCode = status;
      return addOneFeedbackResponseData;
    }
    return null;
  },
);

// delete one feedback
export const deleteOneFeedbackAsync = createAsyncThunk(
  'main/feedback/delete-one',
  async (
    data: IDeleteOneFeedbackRequestData,
  ): Promise<Nullable<IGeneralResponse | IGetAllFeedbacksResponse>> => {
    const { lang, token, feedbackId } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const deleteOneFeedbackResponse: Undefinable<Response> = await universalFetch(
      `${DELETE_ONE_FEEDBACK_URL(feedbackId)}?${params}`,
      RequestRESTMethodsEnum.DELETE,
      undefined,
      token,
    );
    if (deleteOneFeedbackResponse) {
      if (!deleteOneFeedbackResponse.ok) {
        return (await deleteOneFeedbackResponse.json()) as IGeneralResponse;
      }
      const getAllFeedbacksResponse: Undefinable<Response> = await universalFetch(
        `${GET_ALL_FEEDBACKS_URL}?${params}`,
        RequestRESTMethodsEnum.GET,
        undefined,
        token,
      );
      if (getAllFeedbacksResponse) {
        const deleteOneFeedbackResponseData: IGeneralResponse =
          await deleteOneFeedbackResponse.json();
        const getAllFeedbacksResponseData: IGetAllFeedbacksResponse =
          await getAllFeedbacksResponse.json();
        const { status } = deleteOneFeedbackResponse;
        const { message } = deleteOneFeedbackResponseData;
        getAllFeedbacksResponseData.message = message;
        getAllFeedbacksResponseData.statusCode = status;
        return getAllFeedbacksResponseData;
      }
    }
    return null;
  },
);

// delete all feedbacks
export const deleteAllFeedbacksAsync = createAsyncThunk(
  'main/feedback/delete-all',
  async (
    data: IGeneralRequestWithAuth,
  ): Promise<Nullable<IGeneralResponse | IGetAllFeedbacksResponse>> => {
    const { lang, token } = data;
    const params = new URLSearchParams();
    params.set('lang', lang);
    const deleteAllFeedbacksResponse: Undefinable<Response> = await universalFetch(
      `${DELETE_ALL_FEEDBACKS_URL}?${params}`,
      RequestRESTMethodsEnum.DELETE,
      undefined,
      token,
    );
    if (deleteAllFeedbacksResponse) {
      if (!deleteAllFeedbacksResponse.ok) {
        return (await deleteAllFeedbacksResponse.json()) as IGeneralResponse;
      }
      const getAllFeedbacksResponse: Undefinable<Response> = await universalFetch(
        `${GET_ALL_FEEDBACKS_URL}?${params}`,
        RequestRESTMethodsEnum.GET,
        undefined,
        token,
      );
      if (getAllFeedbacksResponse) {
        const deleteAllFeedbacksResponseData: IGeneralResponse =
          await deleteAllFeedbacksResponse.json();
        const getAllFeedbacksResponseData: IGetAllFeedbacksResponse =
          await getAllFeedbacksResponse.json();
        const { status } = deleteAllFeedbacksResponse;
        const { message } = deleteAllFeedbacksResponseData;
        getAllFeedbacksResponseData.message = message;
        getAllFeedbacksResponseData.statusCode = status;
        return getAllFeedbacksResponseData;
      }
    }
    return null;
  },
);
