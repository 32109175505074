import { FC, memo } from 'react';
import { useAppSelector } from 'redux/hooks';

import { LoginLogo } from './components/LoginLogo/LoginLogo';
import { LoginForm } from './components/LoginForm/LoginForm';
import { LoginInput } from './components/LoginForm/components/LoginInput/LoginInput';
import { NoAccountTextWithLink } from './components/NoAccountTextWithLink/NoAccountTextWithLink';
import { ButtonRemastered } from 'components/ButtonRemastered/ButtonRemastered';
import { language } from 'utils/language';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { useAuthorizationDataError } from 'pages/MainPageRemastered/hooks/useAuthorizationDataError';
import { AuthorizationDataValidationInfo } from 'pages/MainPageRemastered/components/AuthorizationDataValidationInfo/AuthorizationDataValidationInfo';
import { getAuthStatus } from 'redux/slices/user/exports';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import styles from './LoginSection.module.scss';

export const LoginSection: FC = memo(() => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const { isValidatedFieldEmpty, validationError } = useAuthorizationDataError('login');
  const isLoading = useAppSelector(getAuthStatus) === 'loading';
  const { login } = useThunks();
  const isLoginButtonDisabled = Boolean(validationError || isValidatedFieldEmpty) || isLoading;

  const loginHandler = () => {
    if (isLoginButtonDisabled) return;
    login();
  };
  return (
    <div className={styles.LoginSection}>
      <LoginLogo />
      <LoginForm>
        <LoginInput type="email" isLoginButtonDisabled={isLoginButtonDisabled} />
        <LoginInput type="password" isLoginButtonDisabled={isLoginButtonDisabled} />
        <ButtonRemastered
          isDisabled={isLoginButtonDisabled}
          isWideButton={true}
          isFullWidth={true}
          buttonText={language(currentLanguage, 'signInCap')}
          onClick={loginHandler}
        />
        <AuthorizationDataValidationInfo type="login" validationError={validationError} />
      </LoginForm>
      <NoAccountTextWithLink />
    </div>
  );
});
