import { FC, memo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useAnimateAppearing } from 'pages/MainPageRemastered/hooks/useAnimate';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import styles from './SupportPage.module.scss';

export const SupportPage: FC = memo(() => {
  const aboutMePageRef = useRef(null);
  const currentlyViewedPage = useAppSelector(getCurrentlyViewedPage);
  const isComponentActive = 'support' === currentlyViewedPage;
  useAnimateAppearing(isComponentActive, aboutMePageRef);
  return (
    <div className={styles.SupportPage} ref={aboutMePageRef}>
      <span style={{ fontWeight: 'bold' }}>support me ;)</span>
    </div>
  );
});
