import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { EMPTY_STRING } from 'constants/constants';
import {
  REGISTRATION_APPLIED_DATA_ERRORS_DEFAULTS,
  REGISTRATION_DATA_DEFAULTS,
} from 'redux/slices/user/constants';
import {
  getRegistrationData,
  getRegistrationDataApplied,
  setCurrentRegistrationQuestionNumber,
  setRegistrationData,
  setRegistrationDataApplied,
  setRegistrationDataAppliedErrors,
  setRepeatedPassword,
} from 'redux/slices/user/exports';

export const useRegistrationData = () => {
  const dispatch = useAppDispatch();
  const registrationData = useAppSelector(getRegistrationData);
  const registrationDataApplied = useAppSelector(getRegistrationDataApplied);
  const isRegistrationDataEmpty = Object.values(registrationData).every(
    (value) => value === EMPTY_STRING,
  );
  const isRegistrationDataAppliedEmpty = Object.values(registrationDataApplied).every(
    (value) => value === EMPTY_STRING,
  );

  const clearRegistrationData = () => {
    dispatch(setRegistrationData(REGISTRATION_DATA_DEFAULTS));
    dispatch(setCurrentRegistrationQuestionNumber(1));
    dispatch(setRepeatedPassword(EMPTY_STRING));
  };
  const clearRegistrationDataApplied = () => {
    dispatch(setRegistrationDataApplied(REGISTRATION_DATA_DEFAULTS));
    dispatch(setRegistrationDataAppliedErrors(REGISTRATION_APPLIED_DATA_ERRORS_DEFAULTS));
  };
  const clearAllRegistrationData = () => {
    clearRegistrationData();
    clearRegistrationDataApplied();
  };
  return {
    registrationData,
    registrationDataApplied,
    clearRegistrationData,
    clearRegistrationDataApplied,
    isRegistrationDataEmpty,
    isRegistrationDataAppliedEmpty,
    clearAllRegistrationData,
  };
};
