import { FC, memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getCurrentLanguage } from 'redux/slices/main/exports';
import { language } from 'utils/language';
import {
  getCurrentRegistrationQuestionNumber,
  getRegistrationDataApplied,
  getRegistrationDataAppliedErrors,
  setCurrentRegistrationQuestionNumber,
} from 'redux/slices/user/exports';
import { getIsReadyToSendRegistrationData } from '../../../../utils';
import { LAST_REGISTRATION_QUESTION_NUMBER } from 'constants/constants';
import { ARROW_ROUNDED_ICON_SRC } from 'constants/assets-src';
import { useAnimateAppearing } from './hooks/useAnimate';
import { classNames } from 'helpers/classNames';
import styles from './RegistrationSendReturnButton.module.scss';

export const RegistrationSendReturnButton: FC = memo(() => {
  const registrationSendReturnButtonRef = useRef<HTMLDivElement>(null);
  const registrationSendReturnButtonTextRef = useRef<HTMLSpanElement>(null);
  const registrationSendReturnButtonArrowImageRef = useRef<HTMLImageElement>(null);
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const registrationAppliedData = useAppSelector(getRegistrationDataApplied);
  const registrationAppliedDataErrors = useAppSelector(getRegistrationDataAppliedErrors);
  const registrationQuestionNumber = useAppSelector(getCurrentRegistrationQuestionNumber);
  const isQuestionLast = registrationQuestionNumber === LAST_REGISTRATION_QUESTION_NUMBER;
  const isShown =
    getIsReadyToSendRegistrationData(registrationAppliedData, registrationAppliedDataErrors) &&
    !isQuestionLast;
  useAnimateAppearing(
    isShown,
    registrationSendReturnButtonArrowImageRef,
    registrationSendReturnButtonTextRef,
    registrationSendReturnButtonRef,
  );

  const goToSendingQuestion = () =>
    isShown && dispatch(setCurrentRegistrationQuestionNumber(LAST_REGISTRATION_QUESTION_NUMBER));
  return (
    <div
      className={classNames(styles.RegistrationSendReturnButton, [], {
        [styles.clickable]: isShown,
      })}
      role="button"
      onClick={goToSendingQuestion}
      ref={registrationSendReturnButtonRef}
    >
      <img
        src={ARROW_ROUNDED_ICON_SRC}
        alt={language(currentLanguage, 'roundedUpArrow')}
        ref={registrationSendReturnButtonArrowImageRef}
      />
      <span ref={registrationSendReturnButtonTextRef}>
        {language(currentLanguage, 'toSending')}
      </span>
    </div>
  );
});
