import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { PAGE_CHANGE_DURATION, ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateNavLinksGroupSliding = (
  isNavLinksGroupCurrent: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const NAV_LINKS_GROUP_CURRENT_POSITION = 30;
  const yPosition = !isNavLinksGroupCurrent ? 0 : NAV_LINKS_GROUP_CURRENT_POSITION;
  const {
    timeline: navLinksGroupSlidingTimeline,
    isFirstLoad,
    setIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isNavLinksGroupCurrent]);
  const duration = isFirstLoad ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION;
  const delay = isFirstLoad ? ZERO_GSAP_DURATION : PAGE_CHANGE_DURATION / 2;
  navLinksGroupSlidingTimeline &&
    navLinksGroupSlidingTimeline.to(scopeElement.current, {
      y: yPosition,
      duration,
      delay,
      onComplete: () => setIsFirstLoad(false),
    });
};
