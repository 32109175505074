import { FC, cloneElement, Children, memo } from 'react';

import { ReactChildrenType, RegistrationQuestionType } from 'types/types';
import { useRegistrationApply } from './hooks/useRegistrationApply';
import { getIsReadyToSendRegistrationData } from '../../../../utils';
import styles from './RegistrationApplyWrapper.module.scss';

interface Props {
  children?: ReactChildrenType;
  type: RegistrationQuestionType;
  validationError: string;
  isValidatedFieldEmpty: boolean;
}

export const RegistrationApplyWrapper: FC<Props> = memo(
  ({ children, type, validationError, isValidatedFieldEmpty }) => {
    const {
      onClick,
      onEnterPress,
      isDisabled,
      registrationApplyButtonText,
      registrationData,
      registrationAppliedData,
      registrationAppliedDataErrors,
      isLoading,
    } = useRegistrationApply(type, validationError, isValidatedFieldEmpty);
    const isRegistrationDataSendButtonDisabled =
      !getIsReadyToSendRegistrationData(registrationAppliedData, registrationAppliedDataErrors) ||
      isLoading;
    const isRegistrationDataSendButton = type === 'check';
    const isRegistrationApplyButtonDisabled = isRegistrationDataSendButton
      ? isRegistrationDataSendButtonDisabled
      : isDisabled;
    return (
      <div className={styles.RegistrationApplyWrapper}>
        {children &&
          Children.map(
            children,
            (child) =>
              child &&
              cloneElement(child, {
                type,
                isDisabled: isRegistrationApplyButtonDisabled,
                onClick,
                onEnterPress,
                buttonText: registrationApplyButtonText,
                registrationData,
                registrationAppliedData,
                registrationAppliedDataErrors,
                isLoading,
              }),
          )}
      </div>
    );
  },
);
