import { ChangeEvent, FC, memo, useCallback, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import {
  getRepeatedPassword,
  setRegistrationData,
  setRepeatedPassword,
} from 'redux/slices/user/exports';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { EnterPressVoidMethod, RegistrationDataType, RegistrationQuestionType } from 'types/types';
import { language } from 'utils/language';
import { getInputData } from 'utils/utils';
import { PasswordVisibilitySwitcher } from 'components/PasswordVisibilitySwitcher/PasswordVisibilitySwitcher';
import styles from './RegistrationQuestionAnswer.module.scss';

interface Props {
  type?: RegistrationQuestionType;
  onEnterPress?: EnterPressVoidMethod;
  isRepeatedPassword?: boolean;
  registrationData?: RegistrationDataType;
}

export const RegistrationQuestionAnswer: FC<Props> = memo(
  ({ type, onEnterPress, isRepeatedPassword, registrationData }) => {
    const registrationInputRef = useRef<HTMLInputElement>(null);
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const dispatch = useAppDispatch();
    const repeatedPassword = useAppSelector(getRepeatedPassword);
    const currentLanguage = useAppSelector(getCurrentLanguage);
    const isQuestionLast = type === 'check';
    const currentRegistrationDataValue = !isQuestionLast ? registrationData[type] : null;
    const { inputType, placeholder: registrationInputPlaceholder } = getInputData(
      type,
      false,
      isRepeatedPassword,
      isPasswordShown,
      currentLanguage,
    );
    const isPasswordInput = type === 'password';
    const isPasswordVisibilitySwitcherDisplayed =
      isPasswordInput && !!currentRegistrationDataValue && !isRepeatedPassword;
    const togglePasswordVisibility = useCallback(() => {
      setIsPasswordShown(!isPasswordShown);
    }, [isPasswordShown]);
    const updateRegistrationData = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      dispatch(setRegistrationData({ ...registrationData, [type]: value }));
    };
    const updateRepeatedPassword = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      dispatch(setRepeatedPassword(value));
    };
    const placeholder = isRepeatedPassword
      ? language(currentLanguage, 'onceAgainCap')
      : registrationInputPlaceholder;
    const value = isRepeatedPassword ? repeatedPassword : currentRegistrationDataValue;
    const onChangeHandler = isRepeatedPassword ? updateRepeatedPassword : updateRegistrationData;
    return (
      <div className={styles.RegistrationQuestionAnswer}>
        <input
          type={inputType}
          placeholder={placeholder}
          value={value}
          spellCheck={false}
          onChange={onChangeHandler}
          onKeyDown={onEnterPress}
          ref={registrationInputRef}
        />
        <PasswordVisibilitySwitcher
          isDisplayed={isPasswordVisibilitySwitcherDisplayed}
          isPasswordShown={isPasswordShown}
          onClickToggler={togglePasswordVisibility}
        />
      </div>
    );
  },
);
