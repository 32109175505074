import { Component, ErrorInfo } from 'react';

import { ErrorPage } from './ErrorPage';
import { ReactChildrenType } from 'types/types';

type Props = {
  children: ReactChildrenType;
};

type State = {
  hasError: boolean;
  error?: Error;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log(error, info);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    return hasError && error ? <ErrorPage errorData={error} /> : children;
  }
}
