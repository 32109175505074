import { FC, memo, useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../redux/hooks';

import { useRoutes } from '../hooks/useRoutes/useRoutes';
import { useLocalStorageSave } from '../hooks/useLocalStorageSave';
import { useLocalStorageLoad } from '../hooks/useLocalStorageLoad';
import { WebSocketType } from 'types/types';
import {
  getCurrentLanguage,
  getIsAuthModalActive,
  getIsPasswordRecoveryMode,
  getIsPasswordRecoveryProcessStarted,
  getPasswordRecoveringEmail,
  setIsPasswordRecoveryMode,
  setIsPasswordRecoveryProcessStarted,
  setPasswordRecoveringEmail,
  setOnlineUsersNicknames,
  setIsAuthModalActive,
} from 'redux/slices/main/exports';
import {
  getIsAuth,
  getRecoveryCodeTimerTimestamp,
  getRecoverSessionTimerTimestamp,
  getRecoverySessionId,
  setRecoverySessionId,
  setRecoveryCodeTimerTimestamp,
  setRecoverSessionTimerTimestamp,
} from 'redux/slices/user/exports';
import { SystemButtons } from './SystemButtons/SystemButtons';
import { LanguageSwitcher } from './SystemButtons/components/LanguageSwitcher/LanguageSwitcher';
import { NavLinksGroup } from './SystemButtons/components/NavLinksGroup/NavLinksGroup';
import { NavLink } from './SystemButtons/components/NavLinksGroup/components/NavLink/NavLink';
import { Notificator } from './Notificator/Notificator';
import { HEART_ICON_SRC } from 'constants/assets-src';
import { UserSideMenuOpener } from './SystemButtons/components/NavLinksGroup/components/UserSideMenuOpener/UserSideMenuOpener';
import { Copyright } from 'components/Copyright/Copyright';

interface Props {
  socket: WebSocketType;
}

export const App: FC<Props> = memo(({ socket }) => {
  const dispatch = useAppDispatch();
  const routes = useRoutes();
  const localStorageSave = useLocalStorageSave();
  const localStorageLoad = useLocalStorageLoad();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isAuth = useAppSelector(getIsAuth);
  const isAuthModalActive = useAppSelector(getIsAuthModalActive);
  const recoveryCodeTimerTimestamp = useAppSelector(getRecoveryCodeTimerTimestamp);
  const recoverSessionTimerTimestamp = useAppSelector(getRecoverSessionTimerTimestamp);
  const recoverySessionId = useAppSelector(getRecoverySessionId);
  const isPasswordRecoveryMode = useAppSelector(getIsPasswordRecoveryMode);
  const isPasswordRecoveryProcessStarted = useAppSelector(getIsPasswordRecoveryProcessStarted);
  const passwordRecoveringEmail = useAppSelector(getPasswordRecoveringEmail);

  const clearPasswordRecoveringData = useCallback(() => {
    recoverySessionId && dispatch(setRecoverySessionId(null));
    recoveryCodeTimerTimestamp && dispatch(setRecoveryCodeTimerTimestamp(null));
    recoverSessionTimerTimestamp && dispatch(setRecoverSessionTimerTimestamp(null));
    isPasswordRecoveryMode && dispatch(setIsPasswordRecoveryMode(false));
    isPasswordRecoveryProcessStarted && dispatch(setIsPasswordRecoveryProcessStarted(false));
    passwordRecoveringEmail && dispatch(setPasswordRecoveringEmail(null));
  }, [
    dispatch,
    isPasswordRecoveryMode,
    isPasswordRecoveryProcessStarted,
    passwordRecoveringEmail,
    recoverSessionTimerTimestamp,
    recoveryCodeTimerTimestamp,
    recoverySessionId,
  ]);

  const websocketsConnectionHandler = () => {
    console.log('WebSocket connection has been established...');
  };

  const updateOnlineUsersNicknames = useCallback(
    (onlineUsersNicknames: Array<string>) => {
      dispatch(setOnlineUsersNicknames(onlineUsersNicknames));
    },
    [dispatch],
  );

  useEffect(() => {
    socket.on('connect', websocketsConnectionHandler);
    socket.on('onlineUsersUpdate', updateOnlineUsersNicknames);
    return () => {
      socket.off('connect', websocketsConnectionHandler);
      socket.off('onlineUsersUpdate', updateOnlineUsersNicknames);
    };
  }, [socket, updateOnlineUsersNicknames]);

  useEffect(() => {
    document.documentElement.lang = currentLanguage === 'ru' ? 'ru' : 'en';
  }, [currentLanguage]);

  useEffect(() => {
    localStorageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): void => {
    if (isAuth) {
      isAuthModalActive && dispatch(setIsAuthModalActive(false));
      clearPasswordRecoveringData();
    }
    localStorageSave();
  }, [clearPasswordRecoveringData, dispatch, isAuth, isAuthModalActive, localStorageSave]);
  return (
    <>
      <Notificator />
      <SystemButtons>
        <NavLinksGroup
          type="main-application"
          mainChild={
            <NavLink
              origin="main-application"
              isMainLinkInGroup
              isAppLink
              linkName="mySportCap"
              linkSrc={'assistant'}
            />
          }
        />
        <NavLinksGroup
          type="information"
          mainChild={
            <NavLink origin="information" isMainLinkInGroup linkName="infoCap" linkSrc={'info'} />
          }
        >
          <NavLink origin="information" linkName="projectsCap" linkSrc={'projects'} />
          <NavLink
            origin="information"
            linkName="supportCap"
            linkSrc={'support'}
            iconSrc={HEART_ICON_SRC}
            iconAltText="heart"
          />
        </NavLinksGroup>
        <NavLinksGroup
          type="authorization"
          mainChild={
            <NavLink
              origin="authorization"
              isMainLinkInGroup
              linkName="signInCap"
              linkSrc={'signin'}
            />
          }
          userSideMenuToggler={<UserSideMenuOpener />}
          isAuthorizationLinksGroup
        >
          <NavLink origin="authorization" linkName="signUpCap" linkSrc={'signup'} />
        </NavLinksGroup>
        <LanguageSwitcher />
      </SystemButtons>
      {routes}
      <Copyright />
    </>
  );
});
