import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { useAppSelector } from 'redux/hooks';

import { getAuthMessage } from 'redux/slices/user/exports';
import { ISystemMessageObject } from 'types/types';
import {
  SYSTEM_ERROR_AUTO_HIDE_DURATION,
  SYSTEM_MESSAGE_AUTO_HIDE_DURATION,
} from 'constants/constants';
import { classNames } from 'helpers/classNames';
import { useAnimateTextAppearing } from './hooks/useAnimate';
import { MESSAGE_DATA_DEFAULTS } from './constants';
import styles from './NotificationMessage.module.scss';

export const NotificationMessage: FC = () => {
  const notificationTimerIdRef = useRef<NodeJS.Timeout>(null);
  const notificationMessageTextRef = useRef<HTMLParagraphElement>(null);
  const [messageData, setMessageData] = useState<ISystemMessageObject>(MESSAGE_DATA_DEFAULTS);
  const [newMessageData, setNewMessageData] = useState<ISystemMessageObject>(MESSAGE_DATA_DEFAULTS);
  const [isShown, setIsShown] = useState(false);
  const { message, severity } = messageData;
  const isPositive = severity === 'positive';
  const isNegative = severity === 'negative';
  const authMessage = useAppSelector(getAuthMessage);

  const showNotificationMessage = useCallback((messageDataToShow: ISystemMessageObject) => {
    setIsShown(true);
    const { severity } = messageDataToShow;
    const isErrorMessage = severity === 'negative';
    const timerValue = isErrorMessage
      ? SYSTEM_ERROR_AUTO_HIDE_DURATION
      : SYSTEM_MESSAGE_AUTO_HIDE_DURATION;
    notificationTimerIdRef.current = setTimeout(() => {
      setIsShown(false);
      notificationTimerIdRef.current = null;
    }, timerValue);
  }, []);
  const clearMessageData = () => {
    const hasNewMessage = newMessageData.message;
    setMessageData(hasNewMessage ? newMessageData : MESSAGE_DATA_DEFAULTS);
    hasNewMessage && setNewMessageData(MESSAGE_DATA_DEFAULTS);
  };
  useAnimateTextAppearing(isShown, clearMessageData, notificationMessageTextRef);

  useEffect(() => {
    if (!authMessage?.message) return;
    notificationTimerIdRef.current && clearTimeout(notificationTimerIdRef.current);
    const isCurrentMessageShouldBeReplaced =
      messageData.message && authMessage.message !== messageData.message;
    if (isCurrentMessageShouldBeReplaced) {
      setNewMessageData(authMessage);
      setIsShown(false);
    } else {
      authMessage.message && setMessageData(authMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authMessage]);
  useEffect(() => {
    messageData.message && showNotificationMessage(messageData);
  }, [messageData, showNotificationMessage]);
  return (
    <div
      className={classNames(styles.NotificationMessage, [], {
        [styles.positive]: isPositive,
        [styles.negative]: isNegative,
      })}
    >
      <p className={styles.NotificationMessageText} ref={notificationMessageTextRef}>
        {message.split('').map((letter, index) => {
          return (
            <span
              className={classNames(styles.NotificationMessageTextLetter, [
                'notification-text-letter',
              ])}
              key={index}
            >
              {letter}
            </span>
          );
        })}
      </p>
    </div>
  );
};
